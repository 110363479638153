import dayjs from "dayjs";

export function getMonth(month = dayjs().month(), year = dayjs().year()) {
  month = Math.floor(month);

  const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day();
  const adjustedFirstDay =
    firstDayOfTheMonth === 0 ? 6 : firstDayOfTheMonth - 1;
  const totalDaysInMonth = dayjs(new Date(year, month + 1, 0)).date();
  const totalDaysInCalendar = adjustedFirstDay + totalDaysInMonth;
  const numberOfRows = Math.ceil(totalDaysInCalendar / 7);

  let currentMonthCount = 1 - adjustedFirstDay;

  const daysMatrix = new Array(numberOfRows).fill([]).map(() => {
    return new Array(7).fill(null).map(() => {
      currentMonthCount++;
      return dayjs(new Date(year, month, currentMonthCount).setUTCHours(0));
    });
  });

  return daysMatrix;
}
