import React from "react";
import AuthContext from "../../store/auth-context";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import {
  fetchEntries,
  resetEntries,
} from "../../store/redux/slices/entriesSlice";
import Calendar from "../Calendar/Calendar";
import Header from "../Header/Header";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import Container from "@mui/material/Container";
import { getDateValue } from "../../services/EntryService";
import GlobalContext from "../../context/GlobalContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const HomePage = () => {
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const { monthIndex, setMonthIndex } = useContext(GlobalContext);

  const msal = useSelector((state) => state.msalInstance.msalInstance);
  const user = useSelector((state) => state.msalInstance.user);

  const userId = user.username;

  function getFirstAndLastDateOfMonth(year, month) {
    // Helper function to format date to 'YYYY-MM-DD'
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Adjust month to 1-based index
      const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if needed
      return `${year}-${month}-${day}`;
    };

    // Get first and last dates of the current month
    const firstDateCurrent = new Date(year, month, 1);
    const lastDateCurrent = new Date(year, month + 1, 0);

    // Get first and last dates of the previous month
    const firstDatePrevious = new Date(year, month - 2, 1);
    const lastDatePrevious = new Date(year, month, 0);

    // Get first and last dates of the next month
    const firstDateNext = new Date(year, month + 1, 1);
    const lastDateNext = new Date(year, month + 2, 0);

    return {
      current: {
        startDate: formatDate(firstDateCurrent),
        endDate: formatDate(lastDateCurrent),
      },
      previous: {
        startDate: formatDate(firstDatePrevious),
        endDate: formatDate(lastDatePrevious),
      },
      next: {
        startDate: formatDate(firstDateNext),
        endDate: formatDate(lastDateNext),
      },
    };
  }

  useEffect(() => {
    async function fetchData() {
      let monthNumber = 0;
      let yearNumber = 0;

      setTimeout(() => {
        monthNumber = parseInt(localStorage.getItem("currentMonth")) + 1;
        yearNumber = parseInt(localStorage.getItem("currentYear"));

        const { current, previous, next } = getFirstAndLastDateOfMonth(
          yearNumber,
          monthNumber
        );

        dispatch(
          fetchEntries({
            username: user.username,
            startDate: previous.startDate,
            endDate: next.endDate,
          })
        )
          .then(() => {
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching entries:", error);
            setIsLoading(false); // Ensure loading state is reset even if there's an error
          });
      }, 1000);
    }
    fetchData();
    getDateValue();
  }, [dispatch, monthIndex]);

  return (
    <div className="bg-primary">
      <Header />

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Container maxWidth="xl pt-67">
          <Calendar admin={false} />
        </Container>
      )}
    </div>
  );
};
export default HomePage;
